<template>
  <div class="w-full h-auto mb-20">
    <div class="h-20"></div>
    <div class="text-3xl font-bold">{{ title }}</div>
    <div class="grid">
      <div class="text-base mt-5 w-5/12 justify-self-center self-center">
        {{ titleDescription }}
      </div>
    </div>
    <div class="grid justify-items-center items-center">
      <div
        class="
          grid grid-cols-3
          justify-items-center
          items-center
          mt-10
          w-3/4
          gap-x-3
        "
      >
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center">
          <img
            :src="leftPicture"
            class="w-32 hover:scale-125 transition ease-out duration-700"
          />
          <div>
            <div class="text-lg font-bold">{{ leftTitle }}</div>
            <div class="text-base mt-3">{{ leftDescription }}</div>
          </div>
        </div>
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center">
          <img
            :src="middlePicture"
            class="w-32 hover:scale-125 transition ease-out duration-700"
          />
          <div>
            <div class="text-lg font-bold">{{ middleTitle }}</div>
            <div class="text-base mt-3">{{ middleDescription }}</div>
          </div>
        </div>
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center">
          <img
            :src="rightPicture"
            class="w-32 hover:scale-125 transition ease-out duration-700"
          />
          <div>
            <div class="text-lg font-bold">{{ rightTitle }}</div>
            <div class="text-base mt-3">{{ rightDescription }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "关于App",
      titleDescription:
        "",

      leftPicture: require("@/pictures/About/About-left.png"),
      leftTitle: "轻松查找人脉",
      leftDescription:
        "根据条件筛选想要的人脉",

      middlePicture: require("@/pictures/About/About-middle.png"),
      middleTitle: "快速查找机遇",
      middleDescription:
        "推荐最符合条件的机遇",

      rightPicture: require("@/pictures/About/About-right.png"),
      rightTitle: "获取曝光数据",
      rightDescription:
        "轻轻一点 数据可查",
    };
  },
};
</script>