<template>
    <div class="bg-gradient-to-r from-left to-right w-full h-auto pt-5 pb-5">
        <div class="text-gray-600">©Copyright {{year}} {{company}}</div>
        <a class="text-gray-600" :href="beian_url" target="_blank">{{beian}}</a>
        <br/>
        <a class="text-gray-600" :href="privacy_policy_url" target="_blank">隐私政策权</a>
    </div>
</template>

<script>
export default {
    data(){
        return{
            company:'杭州小火堆网络科技有限公司',
            year: '2022',
            beian: '浙ICP备16001033号-3',
            beian_url: 'https://beian.miit.gov.cn/',
            privacy_policy_url: './static/privacy_policy.html',
        }
    }
}
</script>