var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-gradient-to-r from-left to-right w-full h-auto"},[_c('div',{staticClass:"bg-feature-shape bg-no-repeat bg-cover"},[_c('div',{staticClass:"grid grid-cols-2 text-left items-center"},[_c('img',{staticClass:"justify-self-end mr-17 mv-12 mb-12 h-pic",attrs:{"src":_vm.picture}}),_c('div',{staticClass:"justify-self-start"},[_c('div',{staticClass:"text-3xl font-bold w-3/5 mb-10 text-text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._l((_vm.list),function(feature,index){return _c('div',{key:index,class:[
            'font-bold',
            'mb-4',
            'w-9/12',
            'text-text',
            'text-ml',
            'duration-2400',
            { 'mt-6': _vm.active != 2 },
            'transition-spacing',
          ]},[_c('div',{class:[
              { 'opacity-50': _vm.active != 2 },
              'transition-opacity',
              'duration-1000',
            ]},[_vm._v(" > "+_vm._s(feature)+" ")])])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }