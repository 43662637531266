<template>
  <div id="app">
    <!-- 导航栏 -->
    <div class="grid items-center justify-items-center">
      <div
        :class="[
          'fixed',
          'z-50',
          'top-0',
          'w-full',
          { 'bg-gradient-to-r': fixed },
          { 'from-left': fixed },
          { 'to-right': fixed },
          { 'border-b': fixed },
          { 'border-white': fixed },
          { 'h-16': fixed },
          { 'h-12': !fixed },
          'transition-colors',
          'duration-600',
          'transition-height',
        ]"
      >
        <div
          :class="[
            'grid',
            'grid-cols-12',
            'items-center',
            'justify-items-center',
            { 'mt-6': !fixed },
            { 'mt-4': fixed },
            'duration-600',
            'transition-spacing',
          ]"
        >
          <div class="flex items-center justify-items-center col-span-6">
            <img :src="logo" class="h-8" />
            <div class="text-logo ml-1 font-bold">
              {{logoText}}
            </div>
          </div>
          <div
            v-for="(name, index) in names"
            :key="index"
            :class="[
              'font-bold',
              'text-base',
              'cursor-pointer',
              'hover:text-selected',
              { 'text-selected': index == active},
              { 'text-unselected': index != active}
            ]"
            @click="scrollTo(index)"
          >
            {{ name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 首页 -->
    <Home name="anchor"></Home>
    <!-- 优点 -->
    <About name="anchor"></About>
    <!-- 特色 -->
    <Feature :active="active" name="anchor"></Feature>
    <!-- 截图 -->
    <Screenshot name="anchor"></Screenshot>
    <!-- 备案等 -->
    <Bottom></Bottom>
  </div>
</template>

<script>
import Home from "./components/Home";
import About from "./components/About";
import Feature from "./components/Feature";
import Screenshot from "./components/Screenshot";
import Bottom from "./components/Bottom";

export default {
  name: "App",
  components: {
    Home,
    About,
    Feature,
    Screenshot,
    Bottom,
  },
  data() {
    return {
      names: ["首页", "关于App", "软件优势", "页面展示"],
      logo: require("@/pictures/Logo/logo.png"),
      logoText: '积墨人脉',
      active: 0,
      fixed: false,
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll, false);
    window.addEventListener("scroll", this.anchorFixed, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    //页面位置改变导航栏颜色
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.getElementsByName("anchor");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop - 200);
      });
      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
        if (
          scrollTop + document.documentElement.clientHeight ===
          document.documentElement.scrollHeight
        ) {
          navIndex = offsetTopArr.length - 1;
        }
      }
      this.active = navIndex;
    },

    //下拉一定距离，导航栏变色固定在上方
    anchorFixed() {
      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 30) {
        this.fixed = true;
      } else {
        this.fixed = false;
      }
    },

    //点击导航栏跳转到指定位置
    scrollTo(index) {
      // 获取目标的 offsetTop
      const targetOffsetTop =
        document.getElementsByName("anchor")[index].offsetTop;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //STEP为移动像素，TOP为控制最高点，START为函数起点，END为函数终点
      let STEP = 50;
      let START = scrollTop > targetOffsetTop ? targetOffsetTop : scrollTop;
      let END = scrollTop > targetOffsetTop ? scrollTop : targetOffsetTop;
      let TOP = Math.round((END - START) / 40);
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          // twoBezier();
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          // twoBezier();
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
      //缓进缓出
      function twoBezier() {
        let t = 1 - Math.abs(targetOffsetTop - scrollTop) / (END - START);
        let step = Math.round(2 * t * (1 - t) * TOP);
        STEP = step == 0 ? 3 : step;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
