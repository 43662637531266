<template>
  <div class="bg-gradient-to-r from-left to-right w-full h-auto">
    <div class="bg-feature-shape bg-no-repeat bg-cover">
      <div class="grid grid-cols-2 text-left items-center">
        <img :src="picture" class="justify-self-end mr-17 mv-12 mb-12 h-pic" />
        <div class="justify-self-start">
          <div class="text-3xl font-bold w-3/5 mb-10 text-text">
            {{ title }}
          </div>
          <div
            v-for="(feature, index) in list"
            :key="index"
            :class="[
              'font-bold',
              'mb-4',
              'w-9/12',
              'text-text',
              'text-ml',
              'duration-2400',
              { 'mt-6': active != 2 },
              'transition-spacing',
            ]"
          >
            <div
              :class="[
                { 'opacity-50': active != 2 },
                'transition-opacity',
                'duration-1000',
              ]"
            >
              > {{ feature }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
  },
  data() {
    return {
      picture: require("@/pictures/Feature/feature-phone.png"),
      title: "积墨人脉核心功能&优势",
      list: [
        "给您推荐最符合您需求的人脉，让您轻松找客户谈合作，开拓行业人脉关系，获取海量目标企业联系人的联系方式。",
        "线上发布好机遇，吸引优质的资源，提高合作效率。",
        "快速交换名片的方式，让您缩短业务达成路径，提高沟通效率。",
        "让您深挖行业合作关系，使您行业人脉获得指数级增长。",
        "精确记录人脉曝光数据，随时随地可查可聊。",
        "注册需认证，项目更靠谱，安全有保障。",
      ],
    };
  },
};
</script>